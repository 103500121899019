import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { formatRut, formatDayMonthYearSlash } from '@fingo/lib/helpers';
import Typography from '@mui/material/Typography';
import { Table } from '@fingo/lib/components/tables';

const headers = [
  { id: 'dateCession', label: 'Fecha cesión', type: 'text', align: 'left', isSearchable: true, mobile: true },
  { id: 'transferorName', label: 'Cedente', type: 'text', align: 'left', isSearchable: true, mobile: true },
  { id: 'assigneeName', label: 'Transferido a', type: 'text', align: 'left', isSearchable: true, mobile: true },
];

const CessionTable = ({ cessions }) => {
  const dataToRows = useCallback((_data) => _data.map((cession) => {
    const {
      id,
      cessionDate,
      transferor,
      assignee,
    } = cession;
    return ({
      id,
      nonSelectable: true,
      transferorName: {
        value: transferor.rut,
        component: (
          <Typography
            align="left"
            variant="p1"
            style={{
              display: 'block',
              overflow: 'inherit',
            }}
          >
            {transferor.name}<br />{formatRut(transferor.rut)}
          </Typography>
        ),
        size: 'small',
      },
      assigneeName: {
        value: assignee.rut,
        component: (
          <Typography
            align="left"
            variant="p1"
            style={{
              display: 'block',
              overflow: 'inherit',
            }}
          >
            {assignee.name}<br />{formatRut(assignee.rut)}
          </Typography>
        ),
        size: 'small',
      },
      dateCession: {
        value: cessionDate,
        component: (
          <Typography
            align="left"
            variant="subtitle1"
            style={{
              display: 'block',
              overflow: 'inherit',
            }}
          >
            {formatDayMonthYearSlash(cessionDate)}
          </Typography>
        ),
        size: 'small',
      },
    });
  }), []);
  return (
    <Table
      initialOrderId="date_cession"
      orderDirection="desc"
      headers={headers}
      data={cessions}
      dataToRows={dataToRows}
      setNewDataOrder={() => {}}
      detailHeaders={headers.filter((header) => !header.mobile)}
      pagination={false}
    />
  );
};
export default CessionTable;

CessionTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  cessions: PropTypes.arrayOf(PropTypes.object).isRequired,
};
