import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useInvoicePreColumns } from '@fingo/lib/constants';
import ValidationTextField from '@fingo/lib/components/inputs/ValidationTextFieldInput';
import { MY_ASSIGNMENTS, MY_OFFERS, MY_PREOFFERS_COMMERCIAL, REEXECUTE_INVOICE_ACTION } from '@fingo/lib/graphql';
import { LoadingButton } from '@mui/lab';
import { useInputRut, useSnackBars } from '@fingo/lib/hooks';
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

const ReexecutionDialog = (
  {
    receedDialog,
    selectedInvoices,
    setSelectedInvoiceIds,
    reexecutionType,
    dialogTitle,
    buttonTitle,
    title,
    open,
    toggleOpen,
    reasonsList },
) => {
  const { addAlert } = useSnackBars();
  const [selectedReason, setSelectedReason] = useState(null);
  const [comments, setComments] = useState(null);
  const { nationalIdentifier: rut, updateNationalIdentifier: updateRut } = useInputRut();
  const columns = useInvoicePreColumns().filter((col) => ['company_Name', 'folio', 'amountWithIva'].includes(col.field));
  const handleClose = () => {
    setSelectedInvoiceIds([]);
    setSelectedReason(null);
    setComments(null);
    toggleOpen(false);
  };
  const [reexecuteInvoicesOperation, { loading }] = useMutation(
    REEXECUTE_INVOICE_ACTION,
    {
      variables: {
        invoicesIds: selectedInvoices.map((invoice) => invoice.id),
        reexecutionType,
        reason: selectedReason,
        comments,
        rut: rut.raw,
      },
      onCompleted: () => {
        addAlert({ id: 'invoice-action-completed', color: 'success', message: 'Accion Completada Correctamente' });
        handleClose();
      },
      refetchQueries: [MY_OFFERS, MY_ASSIGNMENTS, MY_PREOFFERS_COMMERCIAL],
      onError: ({ message }) => addAlert({ id: 'invoice-action-error', color: 'error', message }),
    },
  );
  const pinkLabel = (value) => (
    <Box
      fontWeight="fontWeightMedium"
      display="inline"
      sx={{ color: 'primary.main' }}
    >
      {value}
    </Box>
  );
  return (
    <FingoDialog
      title={dialogTitle}
      maxWidth="lg"
      open={open}
      handleClose={handleClose}
    >
      <Stack direction="column" spacing={1}>
        <Typography>{title}</Typography>
        <FingoDataGrid
          sx={{ height: Math.min(300, 140 + selectedInvoices.length * 60) }}
          rowHeight={50}
          rows={selectedInvoices}
          columns={columns}
        />
        {receedDialog && (
          <>
            <Stack direction="row" sx={{ justifyContent: 'center' }}>
              <ValidationTextField
                name="rut"
                type="text"
                validationtype="rut"
                label="Rut Cesionario"
                value={rut.formatted}
                onChange={(event) => updateRut(event.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Stack>
          </>

        )}
        <Typography>{pinkLabel('Selecciona una razón')}</Typography>
        <RadioGroup
          value={selectedReason}
          onChange={(e) => {
            setSelectedReason(e.target.value);
          }}
        >
          {reasonsList.map((reason) => (
            <FormControlLabel
              key={reason.name}
              value={reason.name}
              control={<Radio />}
              label={reason.label}
            />
          ))}
        </RadioGroup>
        <Typography>{pinkLabel('Comentarios sobre la gestion')}</Typography>
        <TextField
          variant="outlined"
          value={comments}
          onChange={(event) => setComments(event.target.value)}
          minRows={2}
          multiline
        />
        <Stack direction="row" spacing={1} justifyContent="center">
          <LoadingButton
            variant="contained"
            size="small"
            disabled={selectedReason === null || (receedDialog && rut.raw.length < 8)}
            onClick={reexecuteInvoicesOperation}
            loading={loading}
          >
            {buttonTitle}
          </LoadingButton>
          <Button
            variant="contained"
            size="small"
            onClick={handleClose}
          >
            Cancelar
          </Button>
        </Stack>
      </Stack>
    </FingoDialog>
  );
};

ReexecutionDialog.propTypes = {
  receedDialog: PropTypes.bool,
  title: PropTypes.string.isRequired,
  reexecutionType: PropTypes.string.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  setSelectedInvoiceIds: PropTypes.func.isRequired,
  selectedInvoices: PropTypes.arrayOf(
    PropTypes.shape({
      company: PropTypes.shape({
        masterEntity: PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.string,
        }),
      }),
    }),
  ),
  reasonsList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

ReexecutionDialog.defaultProps = {
  receedDialog: false,
  selectedInvoices: [],
};

export default ReexecutionDialog;
