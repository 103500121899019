import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';

const MenuForActions = ({
  actions,
  open,
  anchorEl,
  setAnchorEl,
  ...actionsProps
}) => {
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Menu
      id="long-menu"
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: 48 * 4.5,
          width: '25ch',
        },
      }}
    >
      {actions.map((Action) => (
        <Action {...actionsProps} />
      ))}
    </Menu>
  );
};

MenuForActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.node).isRequired,
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.element.isRequired,
  setAnchorEl: PropTypes.func.isRequired,
};

export default MenuForActions;
