import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useBooleanState, useGetUser } from '@fingo/lib/hooks';
import { userHasRoutePermission } from '@fingo/lib/helpers/routes';
import { hasAssignment, isIn } from '@fingo/lib/helpers/invoice';
import CachedIcon from '@mui/icons-material/Cached';
import ReceedDialog from './ReceedDialog';

const ReceedAction = ({ selectedInvoices, setSelectedInvoiceIds }) => {
  const [open, toggleOpen] = useBooleanState(false);

  const user = useGetUser();
  const canAddAssignment = useMemo(
    () => userHasRoutePermission(user, 'invoices.add_assignment'),
    [user],
  );

  const isDisabled = useMemo(() => {
    if (!canAddAssignment || selectedInvoices.length === 0) return true;

    return selectedInvoices.some(
      (invoice) => isIn(invoice, 'PROCESSING')
        || (isIn(invoice, 'PENDINGTRANSFER')
          && invoice.invoiceMoneyTransfer?.status !== 'WAITING')
        || ((isIn(invoice, 'OFFERED') || isIn(invoice, 'CREATED'))
          && hasAssignment(invoice)),
    );
  }, [canAddAssignment, selectedInvoices]);

  const tooltipTitle = useMemo(() => {
    if (!canAddAssignment) return 'Solamente los administradores pueden receder';
    if (selectedInvoices.length === 0) return 'Selecciona facturas';
    if (isDisabled) return 'Las facturas deben estar cedidas o pendientes de giro para poder receder';
    return null;
  }, [canAddAssignment, selectedInvoices.length, isDisabled]);

  return (
    <>
      <Tooltip title={tooltipTitle}>
        <div>
          <MenuItem
            onClick={toggleOpen}
            disabled={isDisabled}
          >
            <ListItemIcon>
              <CachedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Receder</ListItemText>
          </MenuItem>
        </div>
      </Tooltip>
      {open && (
        <ReceedDialog
          setSelectedInvoiceIds={setSelectedInvoiceIds}
          selectedInvoices={selectedInvoices}
          open={open}
          toggleOpen={toggleOpen}
        />
      )}
    </>
  );
};

ReceedAction.propTypes = {
  setSelectedInvoiceIds: PropTypes.func.isRequired,
  selectedInvoices: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.status,
      company: PropTypes.shape({
        masterEntity: PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.string,
        }),
      }),
      invoiceMoneyTransfer: PropTypes.shape({
        id: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      }),
    }),
  ),
};

ReceedAction.defaultProps = {
  selectedInvoices: [],
};

export default ReceedAction;
