import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useInvoicePreColumns } from '@fingo/lib/constants';
import ValidationTextField from '@fingo/lib/components/inputs/ValidationTextFieldInput';
import { LoadingButton } from '@mui/lab';
import { useInputRut } from '@fingo/lib/hooks';
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import useReceed from '../../../hooks/useReceed';

const RECESION_REASONS = [
  { name: 'DELAYED', label: 'Tardamos mucho en girar' },
  {
    name: 'COMPETITORS_CONDITIONS',
    label: 'Cliente obtuvo mejores condiciones en otra parte',
  },
  { name: 'CLIENT_REGRET', label: 'Cliente ya no necesita dinero' },
  { name: 'ALREADY_PAYED', label: 'Cliente ya recibió el pago' },
  { name: 'OTHER', label: 'Otro' },
];

const HEADERS = ['company_Name', 'folio', 'amountWithIva'];

const ReceedDialog = ({
  selectedInvoices,
  setSelectedInvoiceIds,
  open,
  toggleOpen,
}) => {
  const [selectedReason, setSelectedReason] = useState(null);
  const [comments, setComments] = useState(null);
  const { nationalIdentifier: rut, updateNationalIdentifier: updateRut } = useInputRut();

  const columns = useInvoicePreColumns().filter((col) => HEADERS.includes(col.field));

  const handleClose = useCallback(() => {
    setSelectedInvoiceIds([]);
    setSelectedReason(null);
    setComments(null);
    toggleOpen(false);
  }, []);

  const { receed, loading } = useReceed();

  return (
    <FingoDialog
      title="Recesión de facturas"
      maxWidth="lg"
      open={open}
      handleClose={handleClose}
    >
      <Stack direction="column" spacing={1}>
        <Typography>
          ¿Estás seguro que quieres RECEDER las siguientes facturas? Esta
          operación es IRREVERSIBLE
        </Typography>
        <FingoDataGrid
          sx={{ height: Math.min(300, 140 + selectedInvoices.length * 60) }}
          rowHeight={50}
          rows={selectedInvoices}
          columns={columns}
        />
        <ValidationTextField
          name="rut"
          type="text"
          validationtype="rut"
          label="Rut Cesionario"
          value={rut.formatted}
          onChange={(event) => updateRut(event.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <Typography color="primary" fontWeight="fontWeightMedium">
          Selecciona una razón
        </Typography>
        <RadioGroup
          value={selectedReason}
          onChange={(e) => {
            setSelectedReason(e.target.value);
          }}
        >
          {RECESION_REASONS.map((reason) => (
            <FormControlLabel
              key={reason.name}
              value={reason.name}
              control={<Radio />}
              label={reason.label}
            />
          ))}
        </RadioGroup>
        <Typography color="primary" fontWeight="fontWeightMedium">
          Comentarios sobre la gestion
        </Typography>
        <TextField
          variant="outlined"
          value={comments}
          onChange={(event) => setComments(event.target.value)}
          minRows={2}
          multiline
        />
        <Stack direction="row" spacing={1} justifyContent="center">
          <LoadingButton
            variant="contained"
            size="small"
            disabled={selectedReason === null || rut.raw?.length < 8}
            onClick={() => {
              receed({
                variables: {
                  invoicesIds: selectedInvoices.map((invoice) => invoice.id),
                  reason: selectedReason,
                  comments,
                  rut: rut.raw,
                },
              });
              handleClose();
            }}
            loading={loading}
          >
            Receder
          </LoadingButton>
          <Button variant="contained" size="small" onClick={handleClose}>
            Cancelar
          </Button>
        </Stack>
      </Stack>
    </FingoDialog>
  );
};

ReceedDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  setSelectedInvoiceIds: PropTypes.func.isRequired,
  selectedInvoices: PropTypes.arrayOf(
    PropTypes.shape({
      company: PropTypes.shape({
        masterEntity: PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.string,
        }),
      }),
    }),
  ),
};

ReceedDialog.defaultProps = {
  selectedInvoices: [],
};

export default ReceedDialog;
