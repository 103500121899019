import { EXPORT_INVOICES } from '@fingo/lib/graphql';
import { useExportDocument } from '@fingo/lib/hooks';
import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { DownloadExcelIcon } from '@fingo/lib/components/icons';

const DownloadExcelFromMenu = ({ commonVars, rowCount, ...props }) => {
  const {
    exportDocuments: exportInvoices,
    loading: exportingDocuments,
  } = useExportDocument(EXPORT_INVOICES, { ...commonVars, rowCount });

  return (
    <MenuItem
      {...props}
      onClick={exportInvoices}
      disabled={exportingDocuments}
    >
      <ListItemIcon>
        <DownloadExcelIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Descargar Excel</ListItemText>
    </MenuItem>
  );
};

DownloadExcelFromMenu.propTypes = {
  rowCount: PropTypes.number.isRequired,
  commonVars: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
};

DownloadExcelFromMenu.defaultProps = {
  disabled: false,
};

export default DownloadExcelFromMenu;
