import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useBooleanState } from '@fingo/lib/hooks';
import ReexecutionDialog from './ReexecutionDialog';
import { selectedInvoiceIdsVar } from '../../../graphql/reactive-variables';

const expiredReasons = [
  { name: 'SOLVED_DICOM', label: 'Cliente soluciona/resuelve Dicom' },
  { name: 'SOLVED_DEBT', label: 'Cliente soluciona/resuelve deuda' },
  { name: 'SOLVED_DEBT_INVOICES', label: 'Cliente soluciona/ facturas morosas' },
  { name: 'CREDIT_LINE', label: 'Liberación linea del cliente' },
  { name: 'OTHER', label: 'Otro' },
];

const ReevaluateDialog = ({ selectedInvoices }) => {
  const [open, toggleOpen] = useBooleanState(false);
  return (
    <>
      <Button
        variant="contained"
        size="small"
        onClick={toggleOpen}
        disabled={selectedInvoices.length === 0}
      >
        Reevaluar
      </Button>
      <ReexecutionDialog
        setSelectedInvoiceIds={selectedInvoiceIdsVar}
        selectedInvoices={selectedInvoices}
        reexecutionType="Reevaluate"
        dialogTitle="Reevaluar Facturas"
        buttonTitle="Reevaluar"
        title="Estas seguro que quieres reevaluar las siguientes facturas?"
        open={open}
        toggleOpen={toggleOpen}
        reasonsList={expiredReasons}
      />
    </>
  );
};

ReevaluateDialog.propTypes = {
  selectedInvoices: PropTypes.arrayOf(
    PropTypes.shape({
      company: PropTypes.shape({
        masterEntity: PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.string,
        }),
      }),
    }),
  ),
};

ReevaluateDialog.defaultProps = {
  selectedInvoices: [],
};

export default ReevaluateDialog;
