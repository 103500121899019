import { useQuery } from '@apollo/client';
import { DocumentList } from '@fingo/lib/components/lists';
import {
  useInvoicePreColumns,
  useInvoiceLucilaCustomColumns,
} from '@fingo/lib/constants';
import { OFFERS_COMMERCIAL } from '@fingo/lib/graphql';
import React, { useState } from 'react';
import { formatYearMonthDay } from '@fingo/lib/helpers';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import MoreVert from '@mui/icons-material/MoreVert';
import RaiseOperativeRequestIconButton from '../../../support/components/buttons/RaiseOperativeRequestIconButton';
import { OPERATION_MANAGER_COLUMNS } from '../../constants/invoices-manager';
import {
  ExpiredDialog,
  FactoringSimulationDialog,
  DownloadExcelFromMenu,
  ReceedAction,
  MenuForActions,
} from './headers-actions';

const DEFAULT_FILTER_DAYS = 14;

const OffersManager = () => {
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { data: selectedInvoiceData } = useQuery(OFFERS_COMMERCIAL, {
    variables: { id_In: selectedInvoiceIds, first: 100 },
    skip: selectedInvoiceIds.length === 0,
  });

  const selectedInvoices = selectedInvoiceData?.invoices?.edges.map((edge) => edge.node) || [];

  return (
    <DocumentList
      trackerId="OFFERS"
      type="available-offers"
      headerTitle="Ofertas"
      queryDocument={OFFERS_COMMERCIAL}
      countryFilter
      customVariables={{
        hasValidOffer: true,
        receiver_Rut: null,
        receiverId: null,
        company_MasterEntity_Rut: null,
        dateIssued_Gte: formatYearMonthDay(
          moment().subtract(DEFAULT_FILTER_DAYS, 'days'),
        ),
        dateIssued_Lte: null,
        filterByRequestingPlatformOrAssigned: 'FINGO',
      }}
      checkboxSelection
      includeHeaders={[
        'folio-invoice-profile',
        'dateIssued',
        'company_profile',
        'receiver_sortable',
        'amountWithIva',
        'offerMonthlyRate',
        'offerDefaultRate',
        'offerRetentionRate',
        'executiveAssigned',
        'requirements',
        'uploadDocumentsCommercial',
      ]}
      mobileHeaders={[
        'folio',
        'receiver_Name',
        'preoffer_MonthlyRate',
        'defaultRate',
        'amountWithIva',
      ]}
      allCompaniesOptions
      showFilters
      defaultFilterProps={{
        showStatesFilter: false,
        showDatesFilter: false,
        showCompanyIssuerFilter: true,
        defaultFilterDays: DEFAULT_FILTER_DAYS,
        showExecutiveFilter: true,
        showHuntingFarmingFilter: true,
      }}
      onSelectionModelChange={(ids) => setSelectedInvoiceIds(ids)}
      flexEndButtons={(rowCount, commonVars) => (
        <>
          <FactoringSimulationDialog
            setSelectedInvoiceIds={setSelectedInvoiceIds}
            selectedInvoices={selectedInvoices}
          />
          <RaiseOperativeRequestIconButton documentIds={selectedInvoiceIds} />
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <MoreVert />
          </IconButton>
          <MenuForActions
            actions={[ExpiredDialog, ReceedAction, DownloadExcelFromMenu]}
            selectedInvoices={selectedInvoices}
            setSelectedInvoiceIds={setSelectedInvoiceIds}
            rowCount={rowCount}
            commonVars={commonVars}
            open={open}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />
        </>
      )}
      selectionModel={selectedInvoiceIds}
      preColumns={useInvoicePreColumns(
        useInvoiceLucilaCustomColumns(false).concat(OPERATION_MANAGER_COLUMNS),
      )}
    />
  );
};

export default OffersManager;
