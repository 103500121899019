import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import PropTypes from 'prop-types';
import OnboardingTooltip from '@fingo/lib/components/tooltips/OnboardingTooltip';
import { useBooleanState, useIsMobile } from '@fingo/lib/hooks';

const ProfileCards = ({
  primaryTitle, children, titleActions, customActions, blurred, top, titleElement }) => {
  const showChildren = children.type.name !== 'ProfileTable' || children.props.data?.length > 0;
  const blurredProps = blurred ? { sx: { filter: 'blur(4px)', userSelect: 'none', pointerEvents: 'none' } } : {};
  const isMobile = useIsMobile();
  const [closed, toggleClose] = useBooleanState(true);
  const TitleActions = titleActions.filter(({ hide }) => !hide);
  return (
    <OnboardingTooltip active={blurred}>
      <Card
        raised
        elevation={2}
        sx={{ height: '100%', width: '100%', borderRadius: '8px', position: 'relative' }}
      >
        {primaryTitle && (
          <Stack onClick={toggleClose} direction="row">
            <Typography component="span" variant="h6" color="primary.main" sx={{ mr: '4px', padding: '14px' }}>{primaryTitle}</Typography>
            {titleElement && <Box ml="auto">{titleElement}</Box>}
          </Stack>
        )}
        <Collapse in={isMobile ? closed : true} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
          <Grid container py={2} px={2}>
            <Stack direction="row" mb={3} flex={1}>
              {Boolean(TitleActions.length) && top && (
                TitleActions.map(({ tooltipTitle, id, loading, onClick, disabled, icon }) => (
                  <Stack key={id} direction="row" sx={{ position: 'absolute', top: '5px', right: '15px' }}>
                    <Tooltip title={tooltipTitle || ''} arrow key={id}>
                      <span>
                        {loading ? <Skeleton variant="circular" width={50} height={50} /> : (
                          <IconButton
                            onClick={onClick}
                            disabled={disabled}
                            id={id}
                            size="small"
                          >
                            {icon}
                          </IconButton>
                        )}
                      </span>
                    </Tooltip>
                  </Stack>
                ))
              )}
            </Stack>
            <Grid container {...blurredProps}>
              {showChildren && (children)}
              {!!customActions && customActions}
            </Grid>
          </Grid>
          {Boolean(TitleActions.length) && !top && (
            <Stack
              direction="row"
              sx={{
                backgroundColor: 'gray.light',
                borderRadius: '5px',
                width: '100%',
                justifyContent: 'space-evenly',
                position: 'absolute',
                bottom: 0,
              }}
            >
              {TitleActions.map(
                ({ tooltipTitle, id, loading, onClick, disabled, icon, title }, index) => (
                  <React.Fragment key={id}>
                    <Tooltip title={tooltipTitle || ''} arrow key={id}>
                      <span>
                        {loading && <Skeleton variant="circular" width={50} height={50} />}
                        {!loading && onClick && (
                        <IconButton
                          onClick={onClick}
                          disabled={disabled}
                          id={id}
                        >
                          {icon} <Typography variant="h2" sx={{ ml: '5px', display: { xs: 'none', sm: 'inline', md: 'inline', lg: 'inline' } }}>{title}</Typography>
                        </IconButton>
                        )}
                        {!loading && !onClick && <>{icon}</>}
                      </span>
                    </Tooltip>
                    {(index !== TitleActions.length - 1) && <Divider orientation="vertical" variant="middle" flexItem />}
                  </React.Fragment>
                ),
              )}
            </Stack>
          )}
        </Collapse>
      </Card>
    </OnboardingTooltip>
  );
};

ProfileCards.propTypes = {
  primaryTitle: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.element.isRequired,
  titleActions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    icon: PropTypes.element,
    title: PropTypes.string,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    tooltipTitle: PropTypes.node,
  })),
  top: PropTypes.bool,
  customActions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  blurred: PropTypes.bool,
  titleElement: PropTypes.node,
};
ProfileCards.defaultProps = {
  top: false,
  primaryTitle: '',
  subtitle: '',
  titleActions: [],
  customActions: false,
  blurred: false,
  titleElement: null,
};

export default ProfileCards;
