import { React, useState } from 'react';
import { DocumentList } from '@fingo/lib/components/lists';
import {
  useInvoicePreColumns,
  useInvoiceLucilaCustomColumns,
} from '@fingo/lib/constants';
import { PENDING_DOCUMENTS_INVOICES } from '@fingo/lib/graphql';
import { OPERATION_MANAGER_COLUMNS } from '../../constants/invoices-manager';

const PendingDocumentsManager = () => {
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([]);

  return (
    <DocumentList
      trackerId="PENDING_DOCUMENTS_STATUS"
      type="pending-documents-status"
      headerTitle="Pendiente de documentos"
      queryDocument={PENDING_DOCUMENTS_INVOICES}
      emitter
      countryFilter
      showRefresh={false}
      includeHeaders={[
        'folio-invoice-profile',
        'dateIssued',
        'company_profile',
        'receiver_sortable',
        'amountWithIva',
        'preoffer_MonthlyRate',
        'defaultRate',
        'retentionRate',
        'executiveAssigned',
        'riskRequirements',
        'uploadDocumentsCommercial',
      ]}
      mobileHeaders={['folio', 'company_Name', 'cessionStatus']}
      showFilters
      checkboxSelection
      defaultFilterProps={{
        showStatesFilter: false,
        showDatesFilter: false,
        showInvoiceStatusFilters: false,
        showCompanyIssuerFilter: true,
        showPendingRestrictionsFilter: false,
        defaultFilterDays: 30,
        showExecutiveFilter: true,
        showHuntingFarmingFilter: true,
      }}
      allCompaniesOptions
      selectionModel={selectedInvoiceIds}
      onSelectionModelChange={(ids) => setSelectedInvoiceIds(ids)}
      customVariables={{
        preoffer_Preofferevaluationrequest_Status: 'PendingDocuments',
      }}
      preColumns={useInvoicePreColumns(
        useInvoiceLucilaCustomColumns(false).concat(OPERATION_MANAGER_COLUMNS),
      )}
      keepNonExistentRowsSelected
    />
  );
};

export default PendingDocumentsManager;
