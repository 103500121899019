import { SEND_ASSINGMENT_CESSION_TYPE_CHANGE_MAIL, GET_INVOICES_FOR_CESSION_CHANGE_MAIL } from '@fingo/lib/graphql';
import React, { useCallback, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { PropTypes } from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useSnackBars } from '@fingo/lib/hooks';
import HomeIcon from '@mui/icons-material/Home';
import { everyInvoiceSameCompany, everyInvoiceInProcessing, xmlMissing } from '../../../helpers';

const SendAssignmentCessionTypeChangeMailDialog = ({
  selectedInvoiceIds,
  setSelectedInvoiceIds,
}) => {
  const { data: selectedInvoiceData } = useQuery(
    GET_INVOICES_FOR_CESSION_CHANGE_MAIL,
    {
      variables: { id_In: selectedInvoiceIds },
      skip: selectedInvoiceIds.length === 0,
    },
  );
  const selectedInvoices = selectedInvoiceData
    ? selectedInvoiceData.invoices.edges.map((edge) => edge.node)
    : [];
  const companyRut = useMemo(
    () => selectedInvoices[0]?.company.masterEntity.id,
    [selectedInvoices],
  );
  const everyInvoiceSameCompanyBoolean = useMemo(
    () => everyInvoiceSameCompany(selectedInvoices, companyRut),
    [selectedInvoices, companyRut],
  );
  const everyInvoicesInProcessingBoolean = useMemo(
    () => everyInvoiceInProcessing(selectedInvoices),
    [selectedInvoices],
  );
  const xmlMissingBoolean = useMemo(
    () => xmlMissing(selectedInvoices),
    [selectedInvoices],
  );

  const tooltipMessage = () => {
    if (selectedInvoices.length === 0) return 'Selecciona facturas';
    if (!everyInvoiceSameCompanyBoolean) return 'Las facturas deben pertenecer a la misma compañía';
    if (!everyInvoicesInProcessingBoolean) return 'Las facturas seleccionadas deben estar en estado "Procesando cesión"';
    if (xmlMissingBoolean) return 'Falta el XML de una de las facturas';
    return '';
  };
  const isButtonDisabled = () => tooltipMessage() !== '';

  const { addAlert } = useSnackBars();

  const resetFields = useCallback(() => {
    setSelectedInvoiceIds([]);
  }, []);

  const [
    sendPushEmailCession,
    { loading },
  ] = useMutation(SEND_ASSINGMENT_CESSION_TYPE_CHANGE_MAIL, {
    variables: {
      invoiceIds: selectedInvoices.map((inv) => inv.id),
      newCessionType: 'internal',
    },
    onCompleted: ({ sendAssignmentCessionTypeChangeMail,
    }) => {
      addAlert({
        id: 'alert-send-assingment-cession-type-change-email',
        message: `${sendAssignmentCessionTypeChangeMail.message}`,
        color: 'primary',
        severity: 'success',
      });
      resetFields();
    },
    onError: (err) => {
      addAlert({
        id: 'alert-send-assingment-cession-type-change-email',
        message: `Error: ${err.message}`,
        color: 'error',
        severity: 'error',
      });
      resetFields();
    },
  });

  return (
    <>
      <Tooltip
        title={tooltipMessage()}
      >
        <div>
          <MenuItem
            onClick={sendPushEmailCession}
            loading={loading}
            disabled={isButtonDisabled()}
          >
            <ListItemIcon>
              <HomeIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Cesión interna</ListItemText>
          </MenuItem>
        </div>
      </Tooltip>
    </>
  );
};

SendAssignmentCessionTypeChangeMailDialog.propTypes = {
  selectedInvoiceIds: PropTypes.arrayOf(PropTypes.string),
  setSelectedInvoiceIds: PropTypes.func.isRequired,
};

SendAssignmentCessionTypeChangeMailDialog.defaultProps = {
  selectedInvoiceIds: [],
};

export default SendAssignmentCessionTypeChangeMailDialog;
