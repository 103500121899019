import React from 'react';
import { PropTypes } from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CountUp from 'react-countup';
import isEmpty from 'lodash/isEmpty';
import { buildSummaryData } from '../../../helpers';

const FactoringSimulationSummary = ({ invoicesDataToSimulate }) => {
  const summaryDataToSimulate = !isEmpty(invoicesDataToSimulate)
    ? buildSummaryData(invoicesDataToSimulate)
    : [];
  return (
    <Grid
      container
      sx={{
        bgcolor: 'background.light',
        borderRadius: 2,
        px: 4,
        py: 2,
        '& .MuiGrid-item:first-of-type': {
          pl: 0,
        } }}
    >
      <Grid container sx={{ bgcolor: 'white', borderRadius: 2, py: 3, px: 4 }}>
        {summaryDataToSimulate.map((data) => (
          <Grid container direction="row" justifyContent="space-between" sx={{ px: 5 }} key={data.label}>
            <Typography variant="h5">{data.label}</Typography>
            <Typography variant="h6">
              {data.isMoney ? '$' : ''}
              <CountUp separator="." end={data.value} duration={1} />
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

FactoringSimulationSummary.propTypes = {
  invoicesDataToSimulate: PropTypes.arrayOf(
    PropTypes.shape({
      invoiceId: PropTypes.number,
      monthlyRate: PropTypes.number,
    }),
  ),
};

FactoringSimulationSummary.defaultProps = {
  invoicesDataToSimulate: [],
};

export default FactoringSimulationSummary;
